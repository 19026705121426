import styled from "styled-components"
import { theme } from "@theme"

const fontColors = {
  dark: {
    headingColor: theme.textColor.headingLight,
    textColor: theme.textColor.bodyLight,
    kickerColor: theme.textColor.electricBlue,
  },
  blue: {
    headingColor: theme.textColor.headingLight,
    textColor: theme.textColor.bodyLight,
    kickerColor: theme.textColor.electricBlue,
  },
  light: {
    headingColor: theme.textColor.heading,
    textColor: theme.textColor.grey600,
    kickerColor: "#002DC2",
  },
  white: {
    headingColor: theme.textColor.heading,
    textColor: theme.textColor.grey600,
    kickerColor: "#002DC2",
  },
}

export const HeroUsecaseContainer = styled.div`
  font-family: Roboto, sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 650px;
  align-items: center;
  overflow: hidden;
  ${({ background }) => `
    background: ${background};
  `}
  .background-image-desktop {
    position: absolute;
    bottom: 0;
    min-width: 100vw;
    min-height: 100%;
    z-index: 0;
  }
  .background-image-mobile {
    display: none;
  }
  @media (max-width: 992px) {
    .background-image-mobile {
      display: block;
      position: absolute;
      top: 0;
      min-width: 100vw;
      min-height: 100%;
      z-index: 0;
    }
    .background-image-desktop {
      display: none;
    }
  }
  @media (max-width: 576px) {
    min-height: unset;
  }
  .container {
    z-index: 1;
    display: flex;
    gap: 2%;
    min-height: 650px;
    max-height: 650px;
    margin-left: auto;
    width: 100%;
    justify-content: space-between;
    padding: 0 15px;
    margin: 0 auto;
    max-width: 1170px;
    @media (max-width: 992px) {
      max-width: 770px;
      max-height: unset;
      flex-direction: column;
      margin: 64px auto;
    }
    @media (max-width: 768px) {
      max-width: 506px;
      min-height: unset;
    }
  }

  .text-content-side {
    width: 48%;
    text-align: left;
    display: flex;
    height: 100%;
    flex-direction: column;
    max-width: 570px;
    align-self: start;
    padding-top: 96px;
    ${({ mobileTextAlign }) =>
      mobileTextAlign &&
      mobileTextAlign === "Center" &&
      `
      @media (max-width: 768px) {
        text-align: center;
      }
    `}
    @media (max-width: 992px) {
      margin-bottom: 128px;
    }
    @media (max-width: 992px) {
      margin-bottom: 96px;
    }
  }
  .text-content-wrapper {
    align-items: center;
  }
  .img-side {
    width: 52%;
    display: flex;
    justify-content: center;
    align-self: center;
    img {
      width: 100%;
    }
  }
  .btn-wrapper {
    margin-top: 24px;
    @media (max-width: 768px) {
      margin-top: 15px;
    }
    button {
      margin-right: 15px;
      @media (max-width: 768px) {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: stretch;
    .text-content-side {
      width: 100%;
      padding: 0px;
    }
    .img-side {
      width: 100%;
    }
  }
`

export const Kicker = styled.p`
  color: ${props => fontColors[props?.theme].kickerColor};
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-bottom: 24px;
`

export const Heading = styled.h1`
  color: ${props => fontColors[props?.theme].headingColor};
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 68px;
  line-height: 72px;
  padding-bottom: 24px;
  @media (max-width: 992px) {
    font-size: 50px;
    line-height: 60px;
  }
  @media (max-width: 576px) {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.1px;
  }
`

export const Subhead = styled.div`
  color: ${props => fontColors[props?.theme].textColor};
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const CategoriesWrapper = styled.div`
  margin-bottom: 24px;
`

export const Category = styled.div`
  display: inline-block;
  margin-right: 16px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #101828;
  padding: 5px 10px;
  border-radius: 35px;
`

export const BreadcrumbContainer = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1170px;
  width: 100%;
  align-items: center;
  padding: 25px 15px 0 15px;
  @media (max-width: 992px) {
    max-width: 770px;
    margin-bottom: 32px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
`
